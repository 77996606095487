<template>
    <div>
        <page-header :title="`Chỉnh sửa người phụ trách danh mục`" :items="breadcrumbs" />
        <div v-if="!loading">
            <b-row>
                <b-col lg="12">
                    <b-card>
                        <b-form-group>
                            <label>Tên danh mục <span class="red">*</span></label>
                            <b-form-select v-model="form.name" aria-placeholder="a">
                                <b-form-select-option :value="null" disabled>Danh mục</b-form-select-option>
                                <option v-for="(category) in categories" :key="category.id" :value="category.name"
                                    :disabled="check_categories.includes(category.name) && category.name != nameUser"
                                    :class="[{ 'style-disabled': check_categories.includes(category.name) }]">
                                    {{ category.name }}
                                    <span
                                        v-if="check_categories.includes(category.name) && category.name != nameUser">(đã
                                        có người phụ trách)</span>
                                </option>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group>
                            <label>Người phụ trách <span class="red">*</span></label>
                            <b-form-select v-model="form.in_charge" aria-placeholder="a">
                                <b-form-select-option :value="null" disabled>Người phụ trách</b-form-select-option>
                                <option v-for="(under) in underUsers" :key="under.id" :value="under.id">
                                    {{ under.name }} - {{ under.position }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                        <label>Logo <span class="red">*</span></label>
                        <b-form-group col-lg="4">
                            <a-thumbnail class="my-pond" :value="form.preview" @input="(value) => (form.preview = value)" />
                        </b-form-group>
                        <b-form-group>
                            <label>Thứ tự</label>
                            <b-input type="number" v-model.number="form.order" min="1" max="50" />
                        </b-form-group>
                        <b-form-group>
                            <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                                {{ addErrorMessage }}
                            </div>
                            <b-button variant="success" class="mr-2" @click.prevent="create">
                                Lưu
                            </b-button>
                            <b-button variant="danger" @click.prevent="toListView">
                                Huỷ
                            </b-button>
                        </b-form-group>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import AThumbnail from './partials/a-thumbnail';
import {
    httpClient
} from "../../../_utils/httpClient";
import _ from "lodash";
import Swal from 'sweetalert2';

export default {
    name: 'create',
    components: {
        PageHeader,
        AThumbnail,
    },
    data: function () {
        return {
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Thiết lập người phụ trách',
                to: '/admin/config-assignees',
            }, {
                text: 'Chỉnh sửa',
                to: '#',
                active: true,
            },],
            showAddErrorMessage: false,
            addErrorMessage: '',
            form: {
                name: '',
                preview: '',
                order: '',
                in_charge: '',
            },
            loading: false,
            underUsers: [],
            categories: [],
            check_categories: [],
            nameUser: '',
            logoCategories: []
        };
    },
    watch: {
        'form.preview': {
            handler() {
            }
        },
        'form.name': {
            handler() {
                this.logoCategories.map(item => {
                    if (this.form.name == item.name) {
                        this.form.preview = item.preview
                    }
                })
            }
        }
    },
    methods: {
        async toListView() {
            await this.$router.push({
                name: 'admin.config.assignees'
            })
        },
        validator() {
            if (this.form.in_charge == null) {
                this.showAddErrorMessage = true;
                this.addErrorMessage = `Người phụ trách không được trống`;
                return false;
            }
            if (this.form.name < 1 || this.form.name == null) {
                this.showAddErrorMessage = true;
                this.addErrorMessage = `Tên danh mục không được bỏ trống`;
                return false;
            }
            if (this.form.preview == "") {
                this.showAddErrorMessage = true;
                this.addErrorMessage = `Logo không được bỏ trống`;
                return false;
            }
            return true
        },
        async getUnderUser() {
            let response = await httpClient.get(`user/under`)
            let responseData = response.data;
            if (responseData.code === 0) {
                this.underUsers = responseData.data
            }
        },
        async getCategories() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'category'
                }
            })
            let responseData = response.data;
            console.log(responseData, "responseData")
            if (responseData.code === 0) {
                this.categories = responseData.data
            }
        },
        async getLogoCategories() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'category'
                }
            })
            this.logoCategories = response.data.data
        },
        async create() {
            this.showAddErrorMessage = false
            if (!this.validator()) return
            if (!this.form.order) {
                this.form.order = 1
            }
            let payload = {
                ...this.form,
            }
            await Swal.queue([{
                title: 'Chỉnh sửa người phụ trách danh mục này?',
                confirmButtonText: 'xác nhận',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let response = await httpClient.post(`sentiment-category`, payload)
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Chỉnh sửa người phụ trách danh mục thành công',
                        });
                        await this.toListView()
                    } else {
                        Swal.insertQueueStep({
                            title: 'Chỉnh sửa người phụ trách danh mục không thành công',
                        });
                        this.showAddErrorMessage = true
                        this.addErrorMessage = response.data
                    }
                    return true;
                },
            },]);
        },
        async getById(id) {
            let response = await httpClient.get(`sentiment-category`, {
                params: {
                    id,
                }
            })
            return response.data.data
        },
    },
    async created() {
        let cloneItem = _.cloneDeep(this.$route.params.form);
        if (cloneItem === undefined) {
            cloneItem = await this.getById(this.$route.params.id);
        }
        this.form = {
            ...cloneItem
        }
        this.nameUser = cloneItem.name
        await this.getCategories();
        await this.getUnderUser();
        let response = await httpClient.get(`sentiment-category`)
        if (response.data.data) {
            response.data.data.forEach(item => {
                this.check_categories.push(item.name)
            });
        }
        this.getLogoCategories();
        document.querySelector('.my-pond').addEventListener('FilePond:removefile', () => {
            this.form.preview = ""
        });
    },
};
</script>

<style lang="scss" scoped>
.action-column {
    width: 120px;
    text-align: center;
}

.red {
    color: red;
}
</style>
